import { useState, useEffect } from "react"
import { getQuestions } from "api/questions"
import { trackAction } from "utils/analytics"

export const useInsights = (pageNum = 1, params, routerReady) => {
  const [results, setResults] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)

  useEffect(() => {
    if (!!!pageNum || !routerReady) return
    setIsLoading(true)
    setError(false)

    const controller = new AbortController()
    const { signal } = controller

    pageNum === 1 && setResults([])

    getQuestions(pageNum, params, { signal })
      .then(response => {
        setResults(prev => {
          return [...prev, ...response.data.questions]
        })
        setHasNextPage(Boolean(response.data.meta.next_page))
        setIsLoading(false)
      })
      .catch(e => {
        setIsLoading(false)
        if (signal.aborted) return
        setError(true)
      })

    return () => controller.abort()
  }, [pageNum, params])

  return { isLoading, error, results, hasNextPage }
}

export const useCopyClipboard = () => {
  const [isCopied, setIsCopied] = useState(false)

  const copyTextToClipboard = async text => {
    if ("clipboard" in navigator)
      return await navigator.clipboard.writeText(text)

    return document.execCommand("copy", true, text)
  }

  const handleCopyClick = (text, time = 0, analyticsConf) => {
    copyTextToClipboard(text).then(() => {
      if (analyticsConf) {
        const { label, location, click_text, click_url, modal_name } =
          analyticsConf
        trackAction(label, {
          location,
          click_text,
          click_url,
          modal_name,
        })
      }
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, time)
    })
  }

  return { handleCopyClick, isCopied }
}

export const useScroll = () => {
  const [scrollY, setScrollY] = useState(0)

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return { scrollY }
}
